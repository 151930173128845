import React from "react";

const EducationCard = ({ degree, university, score,duration }) => {
  return (
    <div
            className="education__info my-auto mb-4"
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-easing="ease-out-back"
            data-aos-delay="400"
          >
    <div className="education__card">
      <div className="education__details">
        <h5>{degree}</h5>
        <p className="mb-2 text--light">{university}</p>
        <p className="mb-0 text--light">{score}</p>
      </div>
      <p className="mb-0 text--light ed__year">{duration}</p>
    </div>
    </div>
  );
};

export default EducationCard;
