
// import Bull from '../../images/bull.png'
// import Console from '../../images/console.svg'

import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
// import Header from '../header/header';
import WatchList from '../WatchList/WatchList';

const Positions = () => {

  return (
    
      
       <div className='row'>
              <table className='table-sm  col-md-12 col-sm-12 mx-3 col-12' style={{marginTop:"10px", marginRight:"10px"}}>
                <thead>
                  <tr>
                    
                    <td className="instrument left">
                      <p> Instrument </p>
                    </td>
                    <td className="quantity ">
                      <p> Target P&amp;L </p>
                    </td>
                    <td className="average-price ">
                      <p> Target Price </p>
                    </td>
                    <td className="last-price  ">
                      <p> Entry Price </p>
                    </td>
                    <td className="pnl  ">
                      <p> LTP  </p>
                    </td>
                  
                  </tr>
                </thead>
                <tbody className="a-position-list-container">
                  <tr className='total_p_l_container'>
                    
                    <td className="instrument left">
                      <p className="trade-name-span">
                        NIFTY
                        <p className="trade-day-div">
                          <p className='trade-day'>
                            th <p className='trade-type'>W</p>
                          </p>
                        </p>
                        MAY 17000 PE
                        <p className="nfo"> NFO</p>
                      </p>
                    </td>
                    <td><p>1000</p></td>
                    <td><p> 51.94</p></td>
                    <td><div id="ltp">87</div></td>
                    <td id="pnl" >231</td>   
                    
                  </tr>
                  <tr className='total_p_l_container'>
                    
                    <td className="instrument left">
                      <p className="trade-name-p">
                        NIFTY 5
                        <p className="trade-day-div">
                          <p className='trade-day'>
                            th <p className='trade-type'>W</p>
                          </p>
                        </p>
                        MAY 17000 PE
                        <p className="nfo"> NFO</p>
                      </p>
                    </td>
                    <td><p>1000</p></td>
                    <td><p> 51.94</p></td>
                    <td><div id="ltp">87</div></td>
                    <td id="pnl" >38.23</td>   
                   
                  </tr>
                  <tr className="total_p_l_container">
                    
                    <td>Total</td>
                    <td className="instrument right">41</td>
                    <td>23</td>
                    <td>44</td>
                    
                    <td id="pnl" >43</td>
                  </tr>
                </tbody>
              </table>
              </div>
  );
}


export default Positions;

