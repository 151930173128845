import React, {useEffect} from "react";
import { Image } from "react-bootstrap";
import EducationCard from "../../components/EducationCard";
import CertCard from "../../components/CertCard";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { education, achievementSection } from "../../portfolio";
import "./style.css";
import landingPerson from "../../assets/lottie/94115-reading-boy";

import Lottie from "react-lottie";
const Education = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: landingPerson
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <div
        className="home__wrapper wrapper--global mt-5 pt-5"
        style={{ overflow: "hidden" }}
        id="education"
      >
        <div className="degree__wrapper">
          <center>
            <h1 data-aos="fade-up" data-aos-duration="1500">
              Education
            </h1>
          </center>
          <div
            className="education__svg text-center"
            
          >
            {/* <Image
              src={require("../../assets/images/Education.svg").default}
              className="img-fluid"
            /> */}
            <Lottie style={{width:"20%"}} className="img-fluid" options={defaultOptions} isClickToPauseDisabled={true} />
          </div>
          {/* <div
            className="education__info my-auto"
            data-aos="slide-right"
            data-aos-duration="1500"
            data-aos-easing="ease-out-back"
            data-aos-delay="1000"
          > */}
            {education.map((ach) => {
            return (
              <EducationCard degree={ach.degree} university={ach.university} score={ach.score} duration={ach.duration} />
            );
          })}
            {/* <EducationCard /> */}
            
          {/* </div> */}
        </div>
        {/* <center>
          <h1 className="my-5" data-aos="fade-up" data-aos-duration="1500">
            {achievementSection.title}
          </h1>
        </center>
        <div
          className="cert__svg text-center"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <Image
            src={require("../../assets/images/cert.svg").default}
            className="img-fluid"
          />
        </div>
        <div
          className="certification__wrapper grid--global"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          {achievementSection.achievementsCards.map((ach) => {
            return (
              <CertCard title={ach.title} link={ach.url} reference={ach.ref} />
            );
          })}
        </div> */}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Education;
