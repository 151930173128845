const greeting = {
  username: "Niket Sampat",
  title: "Hi, I'm Niket",
  subTitle:
    "A Chartered Accountant and a passionate Full Stack Developer, prefer to keep implementing the tax domain learnings and do interesting things that matter. Core focus areas include building solutions for GST Compliances, Reconciliation, Data Analytics, Data Transformation and Automation. Keen to pick up new skills and juggle different projects and roles with relative ease.",
  resumeLink:
    "",
};

const socialMediaLinks = [
  {
    id: "github",
    link: "https://github.com/caniket",
    IconifyClassname: "simple-icons:github",
    bgColor: "#333",
  },
  {
    id: "linkedIn",
    link: "https://www.linkedin.com/in/niket-sampat-7455354b/",
    IconifyClassname: "simple-icons:linkedin",
    bgColor: "#0e76a8",
  },
  {
    id: "gmail",
    link:
      "https://mail.google.com/mail/?view=cm&fs=1&to=niket.ca@gmail.com",
    IconifyClassname: "simple-icons:google",
    bgColor: "#ea4335",
  },
  {
    id: "twitter",
    link:
      "https://twitter.com/imniketsampat",
    IconifyClassname: "simple-icons:twitter",
    bgColor: "#00ACEE",
  },
  {
    id: "insta",
    link:
      "https://www.instagram.com/niketsampat",
    IconifyClassname: "simple-icons:instagram",
    bgColor: "#E4405F",
  },
  // {
  //   id: "facebook",
  //   link:
  //     "https://www.facebook.com/niketsampat",
  //   IconifyClassname: "simple-icons:facebook",
  //   bgColor: "#00ACEE",
  // },
];

const FullStack = {
  title: "....as a Full Stack Developer",
  skills: [
    "⚡ Ideating Product Design and Architecture",
    "⚡ Developing highly Interactive and Scalable web applications",
    "⚡ Developing Desktop applications using Electron JS",
    "⚡ Developing mobile applications using React Native",
    "⚡ Creating REST APIs using Flask & Fast Api",
  ],

  stack: [
    {
      skillName: "Python",
      IconifyClassname: "simple-icons:python",
      color: "#3776AB",
    },
    {
      skillName: "FastAPI",
      IconifyClassname: "simple-icons:fastapi",
      color: "#009688",
    },
    {
      skillName: "Flask",
      IconifyClassname: "simple-icons:flask",
      color: "#3776AB",
    },
    {
      skillName: "Pandas",
      IconifyClassname: "simple-icons:pandas",
      color: "#339933",
    },
    {
      skillName: "Numpy",
      IconifyClassname: "simple-icons:numpy",
      color: "#013243",
    },
    {
      skillName: "html",
      IconifyClassname: "simple-icons:html5",
      color: "#E34F26",
    },
    {
      skillName: "css",
      IconifyClassname: "simple-icons:css3",
      color: "#1572B6",
    },
    {
      skillName: "JavaScript",
      IconifyClassname: "simple-icons:javascript",
      color: "#F7DF1E",
    },
    {
      skillName: "TypeScript",
      IconifyClassname: "simple-icons:typescript",
      color: "#F7DF1E",
    },
    {
      skillName: "NextJS",
      IconifyClassname: "simple-icons:nextdotjs",
      color: "#fff",
    },
    {
      skillName: "ReactJS",
      IconifyClassname: "simple-icons:react",
      color: "#61DAFB",
    },
    {
      skillName: "Angular",
      IconifyClassname: "simple-icons:angular",
      color: "#DD0031",
    },
    {
      skillName: "NodeJS",
      IconifyClassname: "simple-icons:nodejs",
      color: "#339933",
    },
    {
      skillName: "MS-SQL",
      IconifyClassname: "simple-icons:microsoftsqlserver",
      color: "#CC2927",
    },
    {
      skillName: "SQLite",
      IconifyClassname: "simple-icons:sqlite",
      color: "#003B57",
    },
    {
      skillName: "ElectronJS",
      IconifyClassname: "simple-icons:electron",
      color: "#47848F",
    },
    {
      skillName: "Nginx",
      IconifyClassname: "simple-icons:nginx",
      color: "#009639",
    },
    {
      skillName: "Docker",
      IconifyClassname: "simple-icons:docker",
      color: "#2496ED",
    },
    {
      skillName: "Firebase",
      IconifyClassname: "simple-icons:fireship",
      color: "#FFCA28",
    },
    {
      skillName: "Azure",
      IconifyClassname: "simple-icons:microsoftazure",
      color: "#0078D4",
    },
    {
      skillName: "Jira",
      IconifyClassname: "simple-icons:jira",
      color: "#FF0000",
    },
    
  ],
};
const CA = {
  title: "....as a Chartered Accountant",
  skills: [
    "⚡ Identifying automation opportunities",
    "⚡ Ideate, Design, Develop and maintain several enterprise level applications for Tax Domain",
    "⚡ Work across teams within domain for integrations",
    "⚡ Conduct Trainings and Demos for applications developed",
    "⚡ Assist in seeking internal approvals and clearances for software applications",
    "⚡ Identifying possible use of new tech stacks and ideas in existing applications",
        
  ],

  
};

const DataScience = {
  title: "Data Science & AI",
  skills: [
    "⚡ Developing Machine Learning models for Regression/ Classification use cases",
    "⚡ Experience of working with Object Detection, Image Classification and NLP projects",
    "⚡ Data collection using Web Scraping libraries like BeautifulSoup",
  ],

  stack: [
    {
      skillName: "Tensorflow",
      IconifyClassname: "simple-icons:tensorflow",
      color: "#FE8333",
    },
    {
      skillName: "Keras",
      IconifyClassname: "simple-icons:keras",
      color: "#C72F2F",
    },
    {
      skillName: "python",
      IconifyClassname: "simple-icons:python",
      color: "#3776AB",
    },
  ],
};

const Cloud = {
  title: "Cloud & Deployment",
  skills: [
    "⚡ Exploring multiple cloud platforms and Docker",
    "⚡ Experience of working with AWS Services like EC2, S3, Amplify",
    "⚡ Deploying web applications on AWS, Heroku platform, Firebase",
  ],
  stack: [
    {
      skillName: "AWS",
      IconifyClassname: "simple-icons:amazonaws",
      color: "#e47911",
    },
    {
      skillName: "Firebase",
      IconifyClassname: "simple-icons:firebase",
      color: "#FFCB2B",
    },
    {
      skillName: "Heroku",
      IconifyClassname: "simple-icons:heroku",
      color: "#9736FF",
    },
    {
      skillName: "Docker",
      IconifyClassname: "simple-icons:docker",
      color: "#2790FF",
    },
  ],
};


const education = [
  {
    degree: "Chartered Accountant",
    university: "Institute of Chartered Accountants of India",
    score:
      "422/800",
    duration: "Jan 2012",
  },
  {
    degree: "Bachelor of Commerce",
    university: "K J Somaiya College of Art and Commerce, Mumbai University",
    score:
      "First Class",
    duration: "March 2009",
  },
  {
    degree: "ISC (12th Grade)",
    university: "Bhawanipur Gujarati Education Society School, ISC, Kolkata",
    score:
      "449/600",
    duration: "March 2006",
  },
  {
    degree: "SSC (10th Grade)",
    university: "M D Bhatia High School, Maharashtra Board",
    score:
      "631/750",
    duration: "March 2004",
  },
];
const workExperiences = [
  {
    role: "Full Stack Developer",
    company: "Ernst & Young LLP",
    desc:
      "Building Web, Desktop and Mobile Applications using Python, React JS, React Native and RestFul APIs using Flask / Fast Api",
    duration: "Jan 2020 - Present",
  },
  {
    role: "Indirect Tax",
    company: "Ernst & Young LLP",
    desc:
      "Assisting clients with Implementation, Advisory and Compliance pertaining to GST and other Indirect Tax Laws in India",
    duration: "Jan 2017 - Dec 2019",
  },
  {
    role: "Direct Tax",
    company: "Ernst & Young LLP",
    desc:
      "Assisting clients with Advisory, Compliance, Assessment and Litigation pertaining to Income Tax and other Direct Tax Laws in India",
    duration: "Oct 2014 - Dec 2016",
  },
  {
    role: "Direct Tax",
    company: "BDO India LLP",
    desc:
      "Assisting clients with Advisory and Compliance pertaining to Income Tax Laws in India",
    duration: "Sept 2012 - Sept 2014",
  },
  {
    role: "Internship",
    company: "M V Sanghvi & Company",
    desc:
      "Assisting clients with Compliance pertaining to Income Tax Laws in India",
    duration: "Feb 2007 - March 2011",
  },
];

const projectsOverview = {
  title: "Projects",
  subtitle:
    "I love to use latest technology stack to develop projects. I have worked on projects from different domain such as Data Science, Data Analytics, Data Transformation and Visualisation. I have worked on building Web, Desktop and Mobile applications using Modern Front End and Back End Stack such as Next JS, React JS, React Native, Angular, Bootstrap, FastAPI etc.",
};

const reactProjects = {
  title: "React JS Projects",
  projects: [
    {
      title: "Data Transformation App",
      // image: "amazon.png",
      // link: "https://github.com/imaaditya-stack/amazon-clone",
    },
    
    {
      title: "QR Scanner and OCR Scanner for GST e-Invoice",
      // image: "netflix.png",
      // link: "https://github.com/imaaditya-stack/netflix-clone",
    },
    {
      title: "GSTR 2B vs Purchase Register vs GL- ITC Matching",
      // image: "reddit.png",
      // link: "https://github.com/imaaditya-stack/reddit-clone",
    },
    {
      title: "GST Audit and Annual Compliances",
      // image: "spotify.png",
      // link: "https://github.com/imaaditya-stack/spotify-clone",
    },
  ],
};

const dsProjects = {
  // title: "Data Science Projects",

  projects: [
    {
      // url: "https://github.com/imaaditya-stack/Weather-image-classification",
      title: "Data Transformation App",
      // description:
      //   "Image Classifier built using Deep learning & Convolutional Neural Network to predict the type of weather given an input image.",
    },
    {
      title: "IRN (e-Invoice) Generator",
      // image: "spotify.png",
      // link: "https://github.com/imaaditya-stack/spotify-clone",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/SpamFilterForQuoraQuestions-DeepLearning",
        title: "QR Scanner and OCR Scanner for GST e-Invoice",
      // description:
      //   "A Deep learning Model to detect whether a question asked on quora is spam or not using Natural Language Processing & LSTM Model",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/Machine-Learning/tree/master/Regression/Counterfeit_Sales",
        title: "GSTR 2B vs Purchase Register - ITC Matching",
      // description:
      //   "Built a predictive model using RandomForestRegressor for predicting sales figures given other information related to counterfeit medicine selling operations",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/Machine-Learning/tree/master/Regression/Counterfeit_Sales",
        title: "GST Audit and Annual Compliances",
      // description:
      //   "Built a predictive model using RandomForestRegressor for predicting sales figures given other information related to counterfeit medicine selling operations",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/Machine-Learning/tree/master/Regression/Counterfeit_Sales",
        title: "PDF Invoice Designer and Generator",
      // description:
      //   "Built a predictive model using RandomForestRegressor for predicting sales figures given other information related to counterfeit medicine selling operations",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/Machine-Learning/tree/master/Regression/Counterfeit_Sales",
        title: "Visualisation Dashboards",
      // description:
      //   "Built a predictive model using RandomForestRegressor for predicting sales figures given other information related to counterfeit medicine selling operations",
    },
    {
      // url:
        // "https://github.com/imaaditya-stack/Machine-Learning/tree/master/Regression/Counterfeit_Sales",
        title: "Customized Reconciliation Reports",
      // description:
      //   "Built a predictive model using RandomForestRegressor for predicting sales figures given other information related to counterfeit medicine selling operations",
    },
  ],
};

const projects = {
  title: "Django/Flask Projects",
  projects: [
    {
      // url: "http://techbeast.herokuapp.com/about",
      title: "TechBeast",
      description:
        "A blog web application coded on an android device during the quarantine period using flask framework & also deployed on heroku platform",
    },
    {
      // url: "https://github.com/imaaditya-stack/Covid19TrackerApi",
      title: "Covid-19 Live Cases Tracker Web App",
      description:
        "A web application to track live cases of COVID-19 in major cities of Maharashtra and all over India using Django and Web Scraping",
    },
  ],
};

const achievementSection = {
  title: "Achievements And Certifications 🏆 ",
  subtitle: "Certifications Stuff that I have done !",

  achievementsCards: [
    {
      title:
        "Post Graduate Certification in Data Science & Artificial Intelligence",
      ref: "Edvancer Edventures",
      // url:
        // "https://drive.google.com/file/d/1JNFJSWKY22bXVDq5HLk4HltBj8NuPSvE/view?usp=sharing",
    },
    {
      title: "Python and Django Full Stack Web Developer Bootcamp",
      ref: "Udemy",
      // url:
        // "https://drive.google.com/file/d/17rEimKGQfRsZUqd5j83DjmXMq3u8RdAu/view?usp=sharing",
    },
    {
      title: "CNN for Computer Vision with Keras and TensorFlow in Python",
      ref: "Udemy",
      // url:
        // "https://drive.google.com/file/d/10E4z_4KyShjjEWnt_RwUJODG8MB06kR-/view?usp=sharing",
    },
  ],
};

const contactInfo = {
  title: "Contact Me",
  subtitle: "Discuss a project or want to say hi? My Inbox is open for all :)",
};

export {
  greeting,
  socialMediaLinks,
  DataScience,
  FullStack,
  CA,
  Cloud,
  workExperiences,
  projectsOverview,
  reactProjects,
  dsProjects,
  projects,
  achievementSection,
  contactInfo,
  education,
};
