import React, { useState, useEffect,useContext } from "react";
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
// import Tradebox from '../Tradebox/Tradebox';
import jsonQuery from 'json-query';
import Context from "../../Context";

const WatchList = () => {
    
    const { contextData, setContextData,fetchinitialdata,fetchprice } = useContext(Context);
    const getAllTradeTypes = ["Custom (1 Leg)", "Custom (2 Legs)", "Custom (3 Legs)", "Custom (4 Legs)", "Bull Call Spread", "Bear Call Spread", "Bull Put Spread", "Bear Put Spread", "Long Straddle", "Long Strangle", "Long Call Butterfly", "Short Call Butterfly", "Long Put Butterfly", "Short Put Butterfly", "Iron Butterfly", "Reverse Iron Butterfly", "Iron Condor", "Jade Lizard", "Long Guts"];
    const [selectedStock, setSelectedStock] = useState("NIFTY");
    const [selected, setSelected] = useState("Custom (1 Leg)");
    const [responsedata, setResponsedata] = useState();
    const [expiry, setExpiry] = useState("");
    const [basestrike, setBasestrike] = useState('');
    const [expirylist, setExpirylist] = useState([]);
    const [strikepricelist, setStrikepricelist] = useState([]);
    const [optionpricelist, setOptionpricelist] = useState('0');
    const [lotsizelist, setLotsizelist] = useState([]);
    const [optiongreeks, setOptiongreeks] = useState('');
    
    const [legTable, setLegTable] = useState({
      "Custom (1 Leg)": ['+C'],
      "Custom (2 Legs)": ['+C', '+C'],
      "Custom (3 Legs)": ['+C', '+C', '+C'],
      "Custom (4 Legs)": ['+C', '+C', '+C', '+C'],
      "Bull Call Spread": ['+C', '-C'],
      "Bear Call Spread": ['+C', '-C'],
      "Bull Put Spread": ['+P', '-P'],
      "Bear Put Spread": ['+P', '-P'],
      "Long Straddle": ['+C', '+P'],
      "Long Strangle": ['+C', '+P'],
      "Long Call Butterfly": ['+C', '-C', '-C', '+C'],
      "Short Call Butterfly": ['-C', '+C', '+C', '-C'],
      "Long Put Butterfly": ['+P', '-P', '-P', '+P'],
      "Short Put Butterfly": ['-P', '+P', '+P', '-P'],
      "Iron Butterfly": ['+P', '-P', '-C', '+C'],
      "Reverse Iron Butterfly": ['-P', '+P', '+C', '-C'],
      "Iron Condor": ['+P', '-P', '-C', '+C'],
      "Jade Lizard": ['-P', '-C', '+C'],
      "Long Guts": ['+C', '+P']
    });
    // console.log(legTable[selected]);
    const tp = event => {
        // event.preventDefualt();
        
        console.log(event);
    }
    const handleexpirylistchange = (event,index) => {
        console.log(index,event.target.value);
        const changeval = index;
        var temp1 =[];
        console.log(contextData.expirylist.length)
        if (contextData.expirylist.length > 1 && contextData.expirylist.length <5 ) {
            temp1 = contextData.expirylist.slice()
            temp1[changeval] = event.target.value.toString();
        }
        else {
            // temp1 = expirylist.slice();
            temp1 = [event.target.value]
        };
        
        console.log(temp1);
        setContextData((prev) => ({
                ...prev,
                expirylist: temp1,
            }));
        // setExpirylist(temp1);
        console.log(contextData.expirylist);
        // fetchprice();
    }
    const addbasestrike = async (event) => {
        // setStrikepricelist([])
        console.log(event);
        const changeval = event;
        const temp1 = contextData.strikepricelist.slice();
        temp1[changeval] = temp1[changeval]+50;
        // for (var i = 0; i < strikepricelist.length; i++) {
        //     if (i===event.target.value) {
        //         console.log("true");
        //         temp1.push(strikepricelist[i]+50);
        //         }            
        //     else {
        //         temp1.push(strikepricelist[i]);
        //     }
        // };
        console.log(temp1);
        setContextData((prev) => ({
            ...prev,
            strikepricelist: temp1,
            
        }));
        // setStrikepricelist(temp1); 
        // console.log(contextData.strikepricelist);
        axios.post("/optionprice", {
            stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[contextData.selected], expiry: contextData.expirylist
          }).then(data => {
            console.log(data);
            setContextData((prev) => ({
                ...prev,
                optionpricelist: data.data.data,
                
            }));
        });
        axios.post("/simulate", {
            stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[contextData.selected], expiry: contextData.expirylist
          }).then(data => {
              console.log(data.data.data)
              setContextData((prev) => ({
                ...prev,
                optiongreeks: data.data.data,
                
            }));
          });
  
    };
    const lessbasestrike = async (event) => {
        // setStrikepricelist([])
        console.log(event);
        const changeval = event;
        const temp1 = contextData.strikepricelist.slice();
        temp1[changeval] = temp1[changeval]-50;
        // for (var i = 0; i < strikepricelist.length; i++) {
        //     if (i===event.target.value) {
        //         console.log("true");
        //         temp1.push(strikepricelist[i]+50);
        //         }            
        //     else {
        //         temp1.push(strikepricelist[i]);
        //     }
        // };
        console.log(temp1);
        setContextData((prev) => ({
            ...prev,
            strikepricelist: temp1,
            
        }));
        axios.post("/optionprice", {
            stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[contextData.selected], expiry: contextData.expirylist
          }).then(data => {
            console.log(data);
            setContextData((prev) => ({
                ...prev,
                optionpricelist: data.data.data,
                
            }));
        });
        axios.post("/simulate", {
            stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[contextData.selected], expiry: contextData.expirylist
          }).then(data => {
              console.log(data.data.data)
              setContextData((prev) => ({
                ...prev,
                optiongreeks: data.data.data,
                
            }));
          });
  
        // setStrikepricelist(temp1); 
        console.log(contextData.strikepricelist);
        // fetchprice();
    };
    const getstrike = (event) => {
        return strikepricelist[event];
    }
    
    const handleStratChange = (event) => {
        // event.preventDefault()
        // setSelected(event);
        var temp1 = [];
        var temp2 = [];
        var temp3 = [];
        // console.log(contextData.expiry[0])
        for (var i = 0; i < legTable[event].length; i++) {
            temp1.push(contextData.basestrike);
            temp2.push(contextData.optionpricelist[0]);
            temp3.push(contextData.expiry[0].toString());
            // console.log(i,temp2)
        };
        // console.log(temp1);
        setContextData((prev) => ({
            ...prev,
            strikepricelist: temp1,
            optionpricelist: temp2,
            expirylist: temp3,
            selected: event,
            
        }));
        axios.post("/simulate", {
            stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[event], expiry: temp3
          }).then(data => {
              console.log(data.data.data)
              setContextData((prev) => ({
                ...prev,
                optiongreeks: data.data.data,
                
            }));
          });
  
        // setStrikepricelist(temp1);
        // setOptionpricelist(temp2);
        // setExpirylist(temp3);
        // fetchprice();
        // console.log(strikepricelist,legTable[event].length);
    };
    // useEffect(() => {
    //     const temp1 = [];
    //     for (var i = 0; i < legTable[selected].length; i++) {
    //         temp1.push(basestrike);
    //     };
    //     // console.log(temp1);
    //     setStrikepricelist(temp1);
    // }, []);
    // const fetchprice = () => {
       
    //     console.log(contextData.strikepricelist);
    //     console.log(legTable[contextData.selected]);
    //     console.log(contextData.expirylist);
    //     axios.post("/optionprice", {
    //         stock: contextData.selectedStock, strikeprice: contextData.strikepricelist, legs: legTable[contextData.selected], expiry: contextData.expirylist
    //       }).then(data => {
    //         console.log(data);
    //         setContextData((prev) => ({
    //             ...prev,
    //             optionpricelist: data.data.data,
                
    //         }));
    //     })};

    const calculate = async () => {
        console.log(contextData.strikepricelist);
        console.log(legTable[contextData.selected]);
        console.log(contextData.expirylist);
        axios.post("/simulate", {
            stock: contextData.selectedStock, strikeprice: contextData.strikepricelist, legs: legTable[contextData.selected], expiry: contextData.expirylist
          }).then(data => {
              console.log(data.data.data)
              setContextData((prev) => ({
                ...prev,
                optiongreeks: data.data.data,
                
            }));
          })};
  
    const convertToDate = (dateString) => {
    let dateObj = new Date(dateString) || "";

    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    let hour = dateObj.getHours();
    let min = dateObj.getMinutes();
    let second = dateObj.getSeconds();
    if (year) {
        let newDate =
        day + "/" + month + "/" + year;
        return newDate;
    }
    return "";
    };
    // const fetchinitialdata = async () => {
    //     await axios.post("/dashboard", {
    //       stock: selectedStock
    //     }).then(data => {
          
            
            
    //             // console.log(JSON.stringify(data.data.data.CE_expiryDate));
    //           setResponsedata(data);
    //         //   setContextData(data);
    //           setExpiry(JSON.parse(JSON.stringify(data.data.expirylist)));
    //           setExpirylist([JSON.parse(JSON.stringify(data.data.data.CE_expiryDate))[0].toString()]);
    //           console.log(contextData);
    //         setBasestrike(Math.round((JSON.parse(JSON.stringify(data.data.data.PE_underlyingValue[0]))) / 100) * 100);
    //         setStrikepricelist([Math.round((JSON.parse(JSON.stringify(data.data.data.PE_underlyingValue[0]))) / 100) * 100]);
            
    //         // console.log(strikepricelist);
    //         // var filteredData = jsonQuery('data.data[**][9][0]', { data: responsedata }).value
    // //         const filteredData = Object.values((JSON.parse(JSON.stringify(data.data)))).filter(dat => dat[0].CE_strikePrice).map(ids => (ids));
    // //   console.log(filteredData)
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    //   };
    useEffect( () => {
      
          
      fetchinitialdata();
    //   fetchprice();
      calculate();
      
    //   addbasestrike();
    }, []);
    useEffect(() => {
        
        console.log(contextData)
        // setStrikepricelist(contextData.strikepricelist);
        // setOptionpricelist(contextData.optionpricelist)
        // setExpirylist(contextData.expirylist);
      //   addbasestrike();
      }, [contextData.strikepricelist,contextData.optionpricelist,contextData.expirylist,contextData.optiongreeks]);
  
    return (
    <>
    {/* <div className="card" > */}
    <div class="container">
        <div className="row justify-content-between">
            <div className="col-md-5 col-sm-5 col-5">
                <Form.Select size="sm" onChange= {event => setSelectedStock(event.target.value)} aria-label="Default select example">
                   
                    <option value="NIFTY">Nifty</option>
                    <option value="BANKNIFTY">Bank Nifty</option>
                    
                </Form.Select>
            </div>
            {/* <div className="col-md-4">
                <Form.Select style= {{height: "30px",fontSize: "12px"}} size="sm" onChange= {event => setSelectedStock(event.target.value)} aria-label="Default select example">
                   {Object.values(expiry).map(k =>
                   <option value={k}>{k}</option>
                    )}
                
                </Form.Select>
            </div> */}
            <div className="col-md-5 col-sm-5 col-5">
                <Form.Select  size="sm" onChange= {event => handleStratChange(event.target.value)} aria-label="Default select example">
                   
                    {contextData.getAllTradeTypes.map((strat,id) =>
                    <option key={id} value={strat}>{strat}</option>
                    )}
                    
                </Form.Select>
            </div>
      
            <div className="col-md-1 col-sm-1 col-1">
                <Button className="bi bi-arrow-clockwise text-light border" size= "sm" variant="outline">
                  
                </Button>     
            </div>
        </div>
        
        
    {/* </div> */}
    <div className="card row mt-4 bg-dark border-secondary " >
        
            <table style= {{marginTop: "10px", marginBottom:"20px"}} className=' table-sm flex-wrap col-sm-12 col-md-12 col-12'>
                <thead >
                <tr >
                    {/* <td>
                        <input className= "pos-position-selectAll" type= "checkbox"/>
                    </td> */}
                    <td>
                        <p  >B/S</p>
                    </td>
                    <td>
                        <p className="text-center" >Expiry</p>
                    </td>
                    <td >
                    <p className="text-center"  > Strike</p>
                    </td>
                    <td>
                        <p  >CE/PE</p>
                    </td>
                    <td>
                        <p >Lot</p>
                    </td>
                    <td>
                        <p  >Price</p>
                    </td>
                    </tr>
                </thead>
                <tbody>
                    {legTable[contextData.selected].map((k,index) => {
                        return (
                        <tr id="optiondata" name="optiondata" key={index}>
                            {/* <td>
                                <input className= "pos-position-selectAll" type= "checkbox"/>
                            </td> */}
                            {k[0].includes('+') ? (
                                <td >
                                    <span className="btn btn-success btn-sm font-weight-bold ">
                                        B
                                    </span>
                                </td>
                            ) : (
                                <td>
                                    <span className="btn btn-danger btn-sm ">
                                        S
                                    </span>
                                </td>
                            )}
                            <td>
                                <Form.Select size="sm" onChange= {event => handleexpirylistchange(event,index)} nocaret="true">
                                    {Object.values(contextData.expiry).map((k,ind) =>
                                        <option key={ind} value={k}>{k}</option>
                                    )}
                                
                                </Form.Select>
                            </td>
                            <td className= "text-center">
                                <div  className=" btn-group" >
                                    <button  className=" btn btn-success btn-sm bi bi-plus" value={index} onClick={(e) => addbasestrike(index)}></button>
                                    <span className=" btn btn-sm btn-secondary  " > {contextData.strikepricelist[index]}</span>
                                    <button className=" btn btn-danger btn-sm bi bi-dash" value={index} onClick={(e) => lessbasestrike(index)}></button>
                                </div>
                            </td>
                            {k[1].includes('C') ? (
                                <td>
                                    <span  className="btn btn-secondary btn-sm ">
                                        CE
                                    </span>
                                </td>
                            ) : (
                                <td>
                                    <span  className="btn btn-primary btn-sm ">
                                        PE
                                    </span>
                                </td>
                            )}
                            <td>
                                <div className= "input-group">
                                    <span className=" btn btn-sm btn-outline-secondary  " > 1</span> 
                                    {/* <input style={{maxWidth:"30px"}} defaultValue ="1" className= "form-control" type= "number"/> */}
                                </div>
                            </td>
                            <td>
                                <div className= "input-group">
                                    <span > {contextData.optionpricelist !=="" && contextData.optionpricelist[index]}</span>
                                    {/* <span style={{height:"25px"}}> {contextData.optiongreeks !=="" && contextData.optiongreeks.price[index].toFixed(4)}</span> */}
                                </div>
                            </td>
                            {/* <td>
                                <i  className= "bi-text-center">
                                   
                                </i>
                            </td>
                            <td>
                                <i  className= "bi-trash">
                                   
                                </i>
                            </td> */}
                        </tr>
                    )})}
                </tbody>
            </table>
            {/* <div className="d-flex my-4">
                <div className="col-md-3 col-sm-3">
                    <Button size="sm" variant="outline-dark" style={{marginLeft:"0px"}}>
                        <span className="bi bi-gear text-light border">Place Order</span>
                    </Button>
                </div>
                <div className="col-md-5 col-sm-5">
                    <Button size="sm" onClick={(e) => calculate()} variant="outline-dark" style={{marginLeft:"5px"}}>
                        <span className="bi bi-gear text-light border">Simulate</span>
                    </Button>
                </div>
                <div className="col-md-3 col-sm-3">
                    <Button size="sm" variant="outline-dark" style={{marginLeft:"5px"}}>
                        <span className="bi bi-gear text-light border">Add Leg</span>
                    </Button>
                </div>
                <div className="col-md-1 col-sm-1">
                    <Button size="sm" variant="outline-dark" style={{marginLeft:"5px"}}>
                        <span className="bi bi-gear text-light border"></span>
                    </Button>
                </div>

            </div> */}
            </div>
            {/* <Tradebox/> */}
    </div>

    </>
    );
}
export default WatchList;