import React from "react";
import TechStack from "../components/home/TechStack";
import { Image } from "react-bootstrap";
import landingPerson from "../assets/lottie/41546-man-working-in-laptop";
import landingPerson1 from "../assets/lottie/108347-designer";
import landingPerson2 from "../assets/lottie/25227-accountant";
import landingPerson3 from "../assets/lottie/codingPerson";

import Lottie from "react-lottie";
const SkillsContainer = ({ svg, skills }) => {
  var dataanimation;
  if (svg === 'FullStack'){
    dataanimation = landingPerson1;}
  else if (svg === 'CA'){
    dataanimation = landingPerson2;}
  else if (svg === 'Datascience'){
    dataanimation = landingPerson3;}
  else {
      dataanimation = landingPerson;}
      
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dataanimation
  };
  return (
    <div
      className="home__wrapper grid--global"
      style={{ overflowX: "hidden" }}
    >
      <div
        className="skills__svg "
        data-aos="slide-right"
        data-aos-duration="1500"

      >
        {/* <Image
          src={require(`../assets/images/${svg}`).default}
          alt=""
          className="img-fluid"
        /> */}
        <Lottie className="img-fluid" options={defaultOptions} isClickToPauseDisabled={true} />
      </div>
      <div
        className="skills__desc"
        data-aos="slide-left"
        data-aos-duration="1500"
      >
        <h2 className="mt-4">{skills.title}</h2>
        <TechStack softwareSkills={skills.stack} />
        {skills.skills.map((skill) => {
          return <p className="text--light text-center mt-2">{skill}</p>;
        })}
      </div>
    </div>
  );
};

export default SkillsContainer;
