import axios from "axios";
const setAxiosDefaults = () => {
  // 
  axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? `https://jcnk4v.deta.dev/` : `https://jcnk4v.deta.dev/`;
  // axios.defaults.headers.common["Authorization"] = "AUTH TOKEN";
  axios.defaults.headers.post["Content-Type"] = "text/plain";
  // axios.defaults.withCredentials = true;
};

export default setAxiosDefaults;
