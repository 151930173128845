import React, { PureComponent,useContext,useState } from 'react';
import {ReferenceLine,ComposedChart,Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,linearGradient, ResponsiveContainer } from 'recharts';
import Context from "../../Context";
const Dchart = () => {
  const flavors = [];

    const positions = [];
  class Option {
    constructor(flavor, position, strike, optionPrice) {
      this.flavor = flavor;
      this.position = position;
      this.strike = strike;
      this.optionPrice = optionPrice;
    }
  }
  // let customSpread = [
	// 	new Option(flavor1, position1, parseFloat(strikePrice1), parseFloat(optionPrice1)),
	// 	new Option(flavor2, position2, parseFloat(strikePrice2), parseFloat(optionPrice2)),
	// 	new Option(flavor3, position3, parseFloat(strikePrice3), parseFloat(optionPrice3)),
	// 	new Option(flavor4, position4, parseFloat(strikePrice4), parseFloat(optionPrice4)),
	// ];
  // for (let i = 0; i < legTable[contextData.selected].length; i++) {
  //   new Option(flavor1, position1, parseFloat(strikePrice1), parseFloat(optionPrice1)),
  // };
  
    
    
  const [legTable, setLegTable] = useState({
    "Custom (1 Leg)": ['+C'],
    "Custom (2 Legs)": ['+C', '+C'],
    "Custom (3 Legs)": ['+C', '+C', '+C'],
    "Custom (4 Legs)": ['+C', '+C', '+C', '+C'],
    "Bull Call Spread": ['+C', '-C'],
    "Bear Call Spread": ['+C', '-C'],
    "Bull Put Spread": ['+P', '-P'],
    "Bear Put Spread": ['+P', '-P'],
    "Long Straddle": ['+C', '+P'],
    "Long Strangle": ['+C', '+P'],
    "Long Call Butterfly": ['+C', '-C', '-C', '+C'],
    "Short Call Butterfly": ['-C', '+C', '+C', '-C'],
    "Long Put Butterfly": ['+P', '-P', '-P', '+P'],
    "Short Put Butterfly": ['-P', '+P', '+P', '-P'],
    "Iron Butterfly": ['+P', '-P', '-C', '+C'],
    "Reverse Iron Butterfly": ['-P', '+P', '+C', '-C'],
    "Iron Condor": ['+P', '-P', '-C', '+C'],
    "Jade Lizard": ['-P', '-C', '+C'],
    "Long Guts": ['+C', '+P']
  });
  

    const { contextData, setContextData,fetchinitialdata } = useContext(Context);
    const data = [{id:"1",val:13,val1:15},{id:"2",val:18,val1:15},{id:"3",val:12,val1:15},{id:"4",val:11,val1:15},{id:"5",val:18,val1:15},{id:"6",val:19,val1:15}];
    var data1 = [];
    var data2 = [];
    
    const { data3, setData3 } = useState('');
    // const data3 = [];
    function singleOptionCalc(  priceRange) {
      let val="val";
      for (var ic in priceRange) {
        data2.push({"id": priceRange[ic]});
      }
      for (let i = 0; i < legTable[contextData.selected].length; i++) {
        var premium = contextData.optionpricelist[i];
        var side = (legTable[contextData.selected][i][0]=== '+') ? 1 : -1;
        var payoff = [];
        var range = [];
        data1=[];
        // console.log(val)
        // data2 = data3;
        if (legTable[contextData.selected][i][1]==='C') {
          let j = 0;
          
          for (var ic in priceRange) {
            if (premium )
            data1.push({"id": priceRange[ic],[val]:((Math.max(priceRange[ic] - contextData.strikepricelist[i], 0)) - (premium)) * side});
            else
            data1.push({"id": priceRange[ic],[val]:1});
            // range.push(priceRange[ic]);
          }
          // console.log(data1);
          
        }
        else {
          for (var ip in priceRange){
            if (premium )
            data1.push({"id": priceRange[ip],[val]:((Math.max(contextData.strikepricelist[i] - priceRange[ip], 0)) - (premium)) * side});
            else
            data1.push({"id": priceRange[ip],[val]:1});
          }
          // console.log(data1);
          
        }
        
        // console.log(payoff,range,data1);
        // console.log(data1)
        let text = i.toString()
        // let result = text.concat(val);
        val = val.concat(text);
        // console.log(data2)
        // const result_Object_assign =
        // data2.map(x => Object.assign({}, x, data1.find(y => y.id === x.id)));
        // console.log(result_Object_assign);
        let result = data2.map(obj => {
          let data = data1.find(item => item.id === obj.id);
          return {...obj, ...data}
        });
        // console.log(result);
        data2 = result;
        // console.log('json2 AFTER:\n' + JSON.stringify(json2));
      }
      var len = data2.length;
        for (let i = 0; i < len; i++) {
          // console.log(data2[i])
          let sum = 0
          for (let x in data2[i]) {
            if (x != 'id') {
              sum = sum + data2[i][x];
            }
            // sum = sum + data2[i][j];
            // console.log(x)
          }
          // console.log(sum)
          data2[i]["net"] = sum
        }

      // console.log(data2);
      return data2;
    }
    function grossPayoff() {
    const start = 17000;
    const end = 19000;
    const step = 100;
    const arrayLength = Math.floor(((end - start) / step)) + 1;
    const range = [...Array(arrayLength).keys()].map(x => (x * step) + start);
    return singleOptionCalc(range);
      
  }
    const type = "payoff";
    // function grossPayoff(option, spotT) {
    //   if (option.flavor == flavors.Call) {
    //     return Math.max(spotT - option.strike, 0);
    //   } else if (option.flavor == flavors.Put) {
    //     return Math.max(option.strike - spotT, 0);
    //   } else {
    //     console.log('Gross payoff error');
    //     return -1;
    //   }
    // }
  
    function netPayoff(option, spotT) {
      let gp = grossPayoff(option, spotT);
      if (option.position == positions.Long) {
        return gp - option.optionPrice;
      } else if (option.position == positions.Short) {
        return option.optionPrice - gp;
      } else {
        console.log('Net payoff error');
        return -1;
      }
    }
    function portfolioPayoff(options) {
      let pp = []; // pp at each spot price
      for (let s = 0; s < spotPrices.length; s++) {
        let ppAtS = 0;
        for (let o = 0; o < options.length; o++) {
          ppAtS += netPayoff(options[o], s);
        }
        pp.push(ppAtS);
      }
      return pp;
    }
    function createChartData(spread, quantities) {
      let payoffs;
      if (quantities == null) {
        payoffs = portfolioPayoff(spread); // y vals
      } else {
        let linearOptions = [];
        for (let i = 0; i < quantities.length; i++) {
          for (let j = 0; j < quantities[i]; j++) {
            linearOptions.push(spread[i]);
          }
        }
        payoffs = portfolioPayoff(linearOptions);
      }
  
      return {
        data: payoffs,
      };
    }
    
    let spotPrices = (() => {
      let list = [];
      for (let i = 0; i < 200; i++) {
        list.push(i);
      }
      return list;
    })();
    // createChartData();
    return (
       
        <div className=" my-4 " >
      <ResponsiveContainer  width="100%" height={275} >
        <ComposedChart data={grossPayoff()} margin={{right:30, left: -10}} >
          <Tooltip />
          <defs>
                    <linearGradient
                        id="linear"
                        x1="0%" y1="100%" x2="0%" y2="0%"
                      
                    >
                       {/* <stop offset="0%" stopColor="#ffe600" /> */}
                        <stop offset="0" stopColor="red" />
                        <stop offset="30%" stopColor="#55198b" />
                    </linearGradient>
                </defs>
          <XAxis dataKey="id" 
              interval={'preserveStartEnd'} />
          <YAxis ></YAxis>
          <Legend  wrapperStyle={{ marginLeft: '2rem', paddingBottom: '10px'}} hide = {1} />
          <ReferenceLine x={18000} stroke="white" label="On Expiry" />
          <ReferenceLine y={0} stroke="white" strokeDasharray="5 5" strokeWidth={2} />
          <Line type="monotone" dataKey="val" stroke="#478559" strokeWidth={1.5} dot={false} />
          <Line type="basis" dataKey="val0" stroke="#51d0de" strokeWidth={1.5} dot={false} />
          <Line type="basis" dataKey="val01" stroke="#cdd422" strokeWidth={1.5} dot={false} />
          <Line type="basis" dataKey="val012" stroke="#bf4aa8" strokeWidth={1.5} dot={false} />
          <Line type="basis" dataKey="val0123" stroke="#8884d8" strokeWidth={1.5} dot={false} />
          <Area type="monotone" dataKey="net" fill="url(#linear)" stroke="red" strokeWidth={3} dot={false} />
          {/* <Line type="basis" dataKey="val1" stroke="#1784d8" strokeWidth={1.5} dot={false} /> */}
        </ComposedChart>
        </ResponsiveContainer>
      </div>
     
    );
};

export default Dchart;