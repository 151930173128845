import React from "react";
import { Image } from "react-bootstrap";
import SocialMedia from "../../components/home/SocialMediaIcons";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { contactInfo } from "../../portfolio";
import "./style.css";

const Contact = () => {
  return (
    <>
      <Header />
      <div
        className="home__wrapper wrapper--global mt-5 pt-5"
        style={{ overflow: "hidden" }}
        id="contact"
      >
        {/* <div
          className="contact__svg text-center"
          data-aos="slide-right"
          data-aos-duration="1500"
        >
          <Image src={require("../../assets/images/contact.svg").default} />
        </div> */}
        <div
          className="home__wrapper  contact__info my-auto text-center"
          data-aos="slide-left"
          data-aos-duration="1500"
        >
          <h1>{contactInfo.title}</h1>
          <p>{contactInfo.subtitle}</p>
          <Image
            src={require("../../assets/images/photo.jpg")}
            className="my-picture"
          />
          <SocialMedia />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
