import axios from "axios";
import React, { useState } from "react";

const Context = React.createContext("");

export const ContextProvider = ({ children }) => {
  const [legTable, setLegTable] = useState({
    "Custom (1 Leg)": ['+C'],
    "Custom (2 Legs)": ['+C', '+C'],
    "Custom (3 Legs)": ['+C', '+C', '+C'],
    "Custom (4 Legs)": ['+C', '+C', '+C', '+C'],
    "Bull Call Spread": ['+C', '-C'],
    "Bear Call Spread": ['+C', '-C'],
    "Bull Put Spread": ['+P', '-P'],
    "Bear Put Spread": ['+P', '-P'],
    "Long Straddle": ['+C', '+P'],
    "Long Strangle": ['+C', '+P'],
    "Long Call Butterfly": ['+C', '-C', '-C', '+C'],
    "Short Call Butterfly": ['-C', '+C', '+C', '-C'],
    "Long Put Butterfly": ['+P', '-P', '-P', '+P'],
    "Short Put Butterfly": ['-P', '+P', '+P', '-P'],
    "Iron Butterfly": ['+P', '-P', '-C', '+C'],
    "Reverse Iron Butterfly": ['-P', '+P', '+C', '-C'],
    "Iron Condor": ['+P', '-P', '-C', '+C'],
    "Jade Lizard": ['-P', '-C', '+C'],
    "Long Guts": ['+C', '+P']
  });
  const convertToDate = (dateString) => {
    
    let temp1 =[];
    
      for (var i = 0; i < dateString.length; i++) {
        let dateObj = new Date(dateString[i]) || "";

        let month = dateObj.getMonth() + 1; //months from 1-12
        let day = dateObj.getDate();
        let year = dateObj.getFullYear();
        let newDate = day + "/" + month + "/" + year;
                // console.log("true");
        temp1.push(newDate);
      }            
            
      console.log(temp1)
      return temp1;
    
    
    };
  const [contextData, setContextData] = useState({
    selectedStock: "NIFTY",
    expiry: "",
    basestrike: "",
    expirylist: "",
    strikepricelist: "",
    optionpricelist: "",
    lotsizelist: "",
    optiongreeks: "",
    selected: "Custom (1 Leg)",
    getAllTradeTypes: ["Custom (1 Leg)", "Custom (2 Legs)", "Custom (3 Legs)", "Custom (4 Legs)", "Bull Call Spread", "Bear Call Spread", "Bull Put Spread", "Bear Put Spread", "Long Straddle", "Long Strangle", "Long Call Butterfly", "Short Call Butterfly", "Long Put Butterfly", "Short Put Butterfly", "Iron Butterfly", "Reverse Iron Butterfly", "Iron Condor", "Jade Lizard", "Long Guts"],
    

    });
    const fetchprice = () => {
      const temp1 = [];
        const temp2 = [];
        const temp3 = [];
        
        for (var i = 0; i < legTable[contextData.selected].length; i++) {
            temp1.push(contextData.strikepricelist);
            // temp2.push(contextData.optionpricelist);
            temp3.push(contextData.expiry[0]);
        }; 
      console.log(contextData.strikepricelist);
      console.log(legTable[contextData.selected]);
      console.log(contextData.expirylist);
      axios.post("/optionprice", {
          stock: contextData.selectedStock, strikeprice: temp1, legs: legTable[contextData.selected], expiry: temp3
        }).then(data => {
          console.log(data);
          setContextData((prev) => ({
              ...prev,
              optionpricelist: data.data.data,
              
          }));
      })};
    const fetchinitialdata = async () => {
        await axios.post("/dashboard", {
          stock: contextData.selectedStock
        }).then(data => {
            console.log(data.data.data);
            setContextData({
              selectedStock: contextData.selectedStock,
              expiry: convertToDate(JSON.parse(JSON.stringify(data.data.expirylist))),
              basestrike: 18000,
              expirylist: convertToDate([JSON.parse(JSON.stringify(data.data.expirylist))[0].toString()]),
              strikepricelist: [17000,17100,17200,17300,17400,17500,17600,17700,17800,17900,18000,18100,18200,18300,18400,18500,18600,18700,18800,18900,19000],
              optionpricelist: "",
              lotsizelist: "",
              optiongreeks: "",
              selected: "Custom (1 Leg)",
              getAllTradeTypes: ["Custom (1 Leg)", "Custom (2 Legs)", "Custom (3 Legs)", "Custom (4 Legs)", "Bull Call Spread", "Bear Call Spread", "Bull Put Spread", "Bear Put Spread", "Long Straddle", "Long Strangle", "Long Call Butterfly", "Short Call Butterfly", "Long Put Butterfly", "Short Put Butterfly", "Iron Butterfly", "Reverse Iron Butterfly", "Iron Condor", "Jade Lizard", "Long Guts"],
              
            });
            axios.post("/optionprice", {
              stock: contextData.selectedStock, strikeprice: [18000], legs: legTable[contextData.selected], expiry: convertToDate([JSON.parse(JSON.stringify(data.data.expirylist))[0].toString()])
            }).then(data => {
              console.log(data);
              setContextData((prev) => ({
                  ...prev,
                  optionpricelist: data.data.data,
                  
              }));
            });
            axios.post("/simulate", {
              stock: contextData.selectedStock, strikeprice: [18000], legs: legTable[contextData.selected], expiry: convertToDate([JSON.parse(JSON.stringify(data.data.expirylist))[0].toString()])
            }).then(data => {
                console.log(data.data.data)
                setContextData((prev) => ({
                  ...prev,
                  optiongreeks: data.data.data,
                  
              }));
            });
                // console.log(JSON.stringify(data.data.data.CE_expiryDate));
            //   setResponsedata(data);
            //   setContext(data);
            //   setExpiry(JSON.parse(JSON.stringify(data.data.expirylist)));
            //   setExpirylist([JSON.parse(JSON.stringify(data.data.data.CE_expiryDate))[0].toString()]);
            //   console.log(context);
            // setBasestrike(Math.round((JSON.parse(JSON.stringify(data.data.data.PE_underlyingValue[0]))) / 100) * 100);
            // setStrikepricelist([Math.round((JSON.parse(JSON.stringify(data.data.data.PE_underlyingValue[0]))) / 100) * 100]);
            
            // console.log(strikepricelist);
            // var filteredData = jsonQuery('data.data[**][9][0]', { data: responsedata }).value
    //         const filteredData = Object.values((JSON.parse(JSON.stringify(data.data)))).filter(dat => dat[0].CE_strikePrice).map(ids => (ids));
    //   console.log(filteredData)
        })
        .catch(e => {
          console.log(e);
        });
      }; 
const value = { contextData, setContextData, fetchinitialdata, fetchprice };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Context;
