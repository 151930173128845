import React, {useEffect} from "react";
import SocialMedia from "../components/home/SocialMediaIcons";
import { Image, Button } from "react-bootstrap";
import landingPerson from "../assets/lottie/41546-man-working-in-laptop";

import Lottie from "react-lottie";
const GreetingContainer = ({ greeting }) => {
  // const animationData = this.props.animationData;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: landingPerson
    };
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
    <div
      className="greeting__wrapper grid--global"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className="greeting__content ">
        <h1>{greeting.title} 👋</h1>
        <p className="text--light">{greeting.subTitle}</p>
        <SocialMedia />
        {/* <Button
          className="button--global"
          size="sm"
          href={greeting.resumeLink}
          target="_blank"
        >
          View my resume
        </Button> */}
      </div>
      <div className="greeting__image">
        {/* <Image
          src={require("../assets/images/Greeting.svg").default}
          alt=""
          className="img-fluid"
        /> */}
        <Lottie className="img-fluid" options={defaultOptions} isClickToPauseDisabled={true} />
      </div>
    </div>
  );
};

export default GreetingContainer;
