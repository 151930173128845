import React from "react";
import { greeting, FullStack,CA,DataScience, Cloud } from "../../portfolio";
import SkillsContainer from "../../containers/Skills";
import GreetingContainer from "../../containers/Greeting";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./style.css";
import Education from "../education";
import Experience from "../experience";
import Projects from "../projects";
import Contact from "../contact";

const Home = () => {
  return (
    <>
      <Header />
      <div className="home__wrapper wrapper--global pt-2" id="home">
        <GreetingContainer greeting={greeting} />
        <center>
          <h1 className="pt-5 mt-10" data-aos="zoom-in" data-aos-duration="1000">
            What I Do?
          </h1>
        </center>
        <SkillsContainer svg="FullStack" skills={FullStack} />
        <SkillsContainer svg="CA" skills={CA} />
        <Education/>
        <Experience/>
        <Projects/>
        <Contact/>
        {/* <SkillsContainer svg="Datascience" skills={DataScience} /> */}
        {/* <SkillsContainer svg="Cloud.svg" skills={Cloud} /> */}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
