import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Education from "./pages/education";
import Experience from "./pages/experience";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import SplashScreen from "./pages/splash";
import PageNotFound from "./shared/PageNotFound";
import AOS from "aos";
import "aos/dist/aos.css";
import Options from './options/options.js'
import { ContextProvider } from "./Context";
const App = () => {
  const appHeight = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      `${window.innerHeight}px`
    );
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  AOS.init({});

  return (
    <ContextProvider>
    <Router>
      <Routes>
        <Route path="/" element={<SplashScreen/>} exact />
        <Route path="/home" element={<Home/>} />
        
        <Route path="/demo" element={<Options/>} />
        {/* <Route path="/contact" element={<Contact} /> */}
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </Router>
    </ContextProvider>
  );
};

export default App;
