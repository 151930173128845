import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Header.css";

const Header = () => {
  return (
    <Navbar
      expand="lg"
      className="py-lg-3 fixed-top"
      // variant="dark"
      data-aos="fade-down"
      data-aos-duration="1000"
    >
      <Navbar.Brand className="mx-3 mb-1" href="/">Niket Sampat</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav " className="mb-2" />
      <Navbar.Collapse className="mr-3" id="basic-navbar-nav ">
        <Nav className="ml-auto pr-3">
          <Nav.Link href="/home#home">Home</Nav.Link>
          <Nav.Link href="/home#education">Education</Nav.Link>
          <Nav.Link href="/home#experience">Experience</Nav.Link>
          <Nav.Link href="/home#projects">Projects</Nav.Link>
          <Nav.Link href="/demo">Demo App</Nav.Link>
          <Nav.Link href="/home#contact">Contact Me</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
