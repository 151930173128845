
// import UserProfile from '../../images/profile.jpg'
// import Gift from '../../images/Gift_kite_banner_light.png'
// import console from '../../images/console.svg'
// import Anker from '../../images/icons/anker.svg'
// import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import Header from "../shared/Header";
import WatchList from '../components/WatchList/WatchList';
// import Toptable from '../Toptable/Toptable';
import Navtable1 from '../components/Navtable1/Navtable1';
// import Bottable from '../Bottable/Bottable';

const Options = () => {
  return (
      <>
      <Header />
      <div className='row mx-2 my-5 pt-2'>
        
        <div className='col-md-5 my-4 col-sm-12'>
          <WatchList />
        </div>
        <div className='col-md-7 my-4 col-sm-12'>
          {/* <Toptable /> */}
          <Navtable1/>
          {/* <Bottable/> */}
        </div>
      </div>
      </>
  );
}


export default Options;

