
// import Bull from '../../images/bull.png'
// import Console from '../../images/console.svg'
import React, {useContext } from "react";
// import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
// import Header from '../header/header';
// import WatchList from '../WatchList/WatchList';
import Context from "../../Context";
const Greeks = () => {
  const { contextData, setContextData,fetchinitialdata } = useContext(Context);
  // console.log(contextData.selectedStock)
  Object.values(contextData.optiongreeks).map((rule,index) =>(
    console.log(rule,index)
  ))
  return (
    
    <div class="container">
       <div className=' '>
        
              <table className='table-sm col-sm-12 col-md-12 col-12 justify-content-start' style={{marginTop:"10px", marginRight:"10px"}}>
                <thead>
                  <tr  >
                    
                    <td className=" align-content-start">
                       Instrument 
                    </td>
                    <td className="left ">
                       Delta 
                    </td>
                    <td className="left ">
                       Theta 
                    </td>
                    <td className="left ">
                       Rho 
                    </td>
                    <td className="left ">
                       Gamma  
                    </td>
                    <td className="left">
                       Vega  
                    </td>
                  
                  </tr>
                </thead>
                <tbody >
                {contextData.optiongreeks !== "" && Object.values(contextData.optiongreeks).map((rule,index) =>(
                
                  <tr key={index+1} >
                    
                    <td className=" left">
                      
                        {contextData.selectedStock}
                        {/* <p className="trade-day-div">
                          <p className='trade-day'>
                            <p className='trade-type'>W
                          
                         */}
                        {contextData.expirylist[index]} {contextData.strikepricelist[index]} {rule.type[index]} 
                        {/* <p className="nfo"> NFO  */}
                    
                    </td>
                    <td>{rule.delta.toFixed(4)}</td>
                    <td> {rule.theta.toFixed(4)}</td>
                    <td>{rule.rho.toFixed(4)}</td>
                
                    <td>{rule.gama.toFixed(4)}</td>
                    <td> {rule.vega.toFixed(4)}</td>
                  </tr>
                ))}
                  {/* {contextData.optiongreeks !== "" && (
                  <tr >
                    
                    <td>Total</td>
                    <td>{Object.values(contextData.optiongreeks.delta).reduce((a,b) =>  a + b).toFixed(4)}</td>
                    <td>{Object.values(contextData.optiongreeks.theta).reduce((a,b) =>  a + b).toFixed(4)}</td>
                    <td>{Object.values(contextData.optiongreeks.rho).reduce((a,b) =>  a + b).toFixed(4)}</td>
                
                    <td>{Object.values(contextData.optiongreeks.gama).reduce((a,b) =>  a + b).toFixed(4)}</td>
                    <td> {Object.values(contextData.optiongreeks.vega).reduce((a,b) =>  a + b).toFixed(4)}</td>
                  </tr>
                  )} */}
                </tbody>
              </table>
              </div>
              </div>
            
  );
}


export default Greeks;

