import React from "react";
import { Image } from "react-bootstrap";
import landingPerson from "../assets/lottie/83087-developer-front-end";

import Lottie from "react-lottie";

const ProjectsOverview = ({ title, subtitle }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: landingPerson
  };
  return (
    <div
      className="home__wrapper  mt-5 pt-5"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className="my-auto pt-1">
        <center>
          <h1>{title}</h1>
     
        </center>
      </div>
      <div className=" home__wrapper">
      <Lottie style={{width:"20%"}} className="img-fluid" options={defaultOptions} isClickToPauseDisabled={true} />
        {/* <Image src={require("../assets/images/Projects.svg").default} /> */}
        <div className="mt-4">
        <center>
          
          <p>{subtitle}</p>
        </center>
      </div>
      </div>
      
    </div>
  );
};

export default ProjectsOverview;
