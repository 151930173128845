// import Bull from '../../images/bull.png'
// import Console from '../../images/console.svg'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Positions from '../positions/Positions';
import Greeks from '../Greeks/Greeks';
// import UserProfile1 from '../../images/chart.jpg';
// import UserProfile2 from '../../images/strategy.jpg';
import Dchart from "./d3linechart";
const Navtable1 = () => {
    const [activeTab, setActiveTab] = useState("salesRegister");
    
  return (
    <>    
      
      {/* <Tabs variant="pills" style={{borderRadius:"20px !important", border:"solid", borderWidth:"1px", width:"400px" }} className="my-3 mx-4" defaultActiveKey="Graph" id="uncontrolled-tab-example" className="mb-3">
        <Tab style={{borderRadius:"20px" }} eventKey="Graph" title="Graph">
        
        </Tab>
        <Tab style={{borderRadius:"20px !important" }} eventKey="PlTable" title="P/L Table">
          
        </Tab>
        <Tab style={{borderRadius:"20px" }} eventKey="Greeks" title="Greeks" >
          
        </Tab>
        <Tab style={{borderRadius:"20px" }} eventKey="Strategychart" title="Strategy Chart" >
          
        </Tab>
    </Tabs> */}
    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
    <div class="container"> 
    <div className='row'>
       <Nav style={{width:"100%",marginLeft:-10}} className=" justify-content-start" variant="pills" defaultActiveKey="first"  >
       
        <Nav.Item >
          <Nav.Link style={{borderRadius:"5px" }} eventKey="first">Pay-off Graph</Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link style={{borderRadius:"25px" }} eventKey="second">P/L Table</Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link style={{borderRadius:"5px" }} eventKey="third" > Greeks</Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link style={{borderRadius:"25px" }} eventKey="fourth" > Strategy </Nav.Link>
        </Nav.Item> */}
      </Nav>
      </div>
      </div>
      <div class="container">
      <div className='card row mt-3 bg-dark border-secondary' style={{minHeight:"100px"}}>
    <Tab.Content>
            <Tab.Pane eventKey="first">
            <Dchart></Dchart>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
             <Positions/>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
             <Greeks></Greeks>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
            {/* <img src={UserProfile2} style={{width:"800px", height:"200px"}} alt="" /> */}
            </Tab.Pane>
          </Tab.Content>
          </div>
          </div>
          </Tab.Container>
            
        
    </>

  );
}


export default Navtable1;

